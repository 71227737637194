import React from "react";

const Portfolio = ({ data }) => {
    const back= {
        backgroundColor:"#ebeeee",
        paddingTop:"0px",
        paddingBottom:"0px"
      
        }
  if (data) {
    var projects = data.projects.map(function (projects) {
      var projectImage =  projects.image;
      return (
        <div key={projects.title} className="columns portfolio-item">
           <h3>{projects.title}</h3>
           <p>{projects.category}</p>
          <div className="item-wrap">
           
            
            <a href={projects.url}>
              <img  src={projectImage} />
              {/* <div className="overlay">
                
                 
                  <p>{projects.category}</p>
                  <p>  <a href={projects.source}>Source Code</a></p>
                
              </div> */}
              {/* <div className="link-icon">
                <i className="fa fa-link">&nbsp;Live&nbsp;Demo</i>
              </div> */}
             
             <pre style={{color:"#ebeeee" , backgroundColor:"#ebeeee"}}><span>Tech Stack</span> - <span style={{color:"black" , backgroundColor:"#ebeeee"}} > {projects.stack}</span></pre>
            </a>
         <div style={{display:"flex" , background:"#ebeeee"}}>
         {/* <button style={{margin:"auto"}}>Live Url</button> */}
         <a style={{margin:"auto"}} href={projects.url}>
         <i className="fa fa-solid fa-rocket"> Live Demo</i>
         </a>

         <a style={{margin:"auto"}} href={projects.source}>
         <i className="fa fa-solid fa-code"> Source Code</i>
         </a>
     
         </div>
        
          </div>
        
          
        </div>
      );
    });
  }

  //   var certifications = data.certifications.map(function (certifications) {
  //     var projectImage = "images/portfolio/" + certifications.image;
  //     return (
  //       <div key={certifications.title}  className="columns portfolio-item">
           
  //         <div className="item-wrap">
  //         <h5>{certifications.title}</h5>
  //           <a href={certifications.url} title={certifications.title}>
            
  //             <img  alt={certifications.title} src={projectImage} />
  //             <div className="overlay">
  //               <div className="portfolio-item-meta">
  //                 <h5>{certifications.title}</h5>
  //                 <p>{certifications.category}</p>
  //               </div>
  //             </div>
  //             <div className="link-icon">
  //               <i className="fa fa-link"></i>
  //             </div>
  //           </a>
  //         </div>
          
  //       </div>
  //     );
  //   });

  // }

  return (
    <section id="projects">

<section id="portfolio" style={back} >
      <div className="row">
        <div className="twelve columns collapsed">
     

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            {projects}
        

            
          </div>
       
        </div>
      </div>
    </section>
    </section>
    
  );
};

export default Portfolio;
