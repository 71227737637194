import React from "react";

const Portfolio = ({ data }) => {
  const mystyle = {
    width:"250%",
     height:"250%",
     paddingTop:"0px"
  }
  const back= {
  backgroundColor:"#2F4F4F",
  paddingTop:"0px",
  paddingBottom:"0px"

  }
  if (data) {
    var certifications = data.certifications.map(function (certifications) {
      var projectImage =  certifications.image;
      return (
        <div key={certifications.title}  className="columns portfolio-item">
           <h3>{certifications.title}</h3>
          <div className="item-wrap">
         
            <a href={certifications.url} title={certifications.title}>
            
              <img  alt={certifications.title} src={projectImage} />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>{certifications.title}</h5>
                  <p>{certifications.category}</p>
                </div>
              </div>
              <div className="link-icon">
                <i className="fa fa-link">&nbsp;Credentials</i>
              </div>
            </a>
          </div>
          
        </div>
      );
    });
 
  }


  return (
    <section id="certificate">
    <section style={back}  id="portfolio">
      <div className="row">
        <div className="columns collapsed">

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            {certifications}
         
          </div>
        </div>
      </div>
    </section>

    </section>

  );
};

export default Portfolio;
