import React from "react";

const Portfolio = ({ data }) => {
  const mystyle = {
    width:"250%",
     height:"250%",
     borderRadius:" 250px",
  }
  const back= {
  backgroundColor:"white"
  }
  const text= {
    textAlign:"center"
    }
  if (data) {
    var testimonials = data.testimonials.map(function (testimonials) {
      var projectImage =  testimonials.image;
      return (
        <div key={testimonials.title} className="columns portfolio-item">
          
          <div className="item-wrap">
         
            <a href={testimonials.url} title={testimonials.title}>
            
              <img style={mystyle} alt={testimonials.title} src={projectImage} />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5 style={text}>{testimonials.title}</h5>
                  <p>{testimonials.category}</p>
                </div>
              </div>
              <div className="link-icon">
                <i className="fa fa-link"></i>
              </div>
            </a>
            <h5>{testimonials.title}</h5>
                  <p>{testimonials.category}</p>
          </div>
          
        </div>
      );
    });
  }


  return (
<section id="coding">  
<section style={back}id="portfolio">
      <div className="row">
        <div className="columns collapsed">
          <h1>Coding Profile</h1>

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters2 s-bgrid-thirds cf"
          >
            {testimonials}
          </div>
        </div>
      </div>
    </section>
</section>
  );
};

export default Portfolio;
